import React from "react";
import AppSidebar from "../components/common/Menu";
import AppHeader from "../components/common/Header";
import AppContent from "./AppContent";

const DefaultLayout = () => {
  return (
    <div id="wrapper">
      <AppSidebar />
      <div className="content-area">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <AppContent />
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
