import React from "react";
import { Navigate } from "react-router-dom";

const Protected = ({ children }) => {
  const user = localStorage.getItem("user") ? true : false;
  if (!user) return <Navigate to="/login" />;
  if (children) return children;
};

export default Protected;
