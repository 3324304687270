import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../config";
const initialState = {
  listContacts: [],
  allContacts: [],
  cLoading: false,
  contactsLoading: false,
  contact: undefined,
  contactById: undefined,
  newContact: undefined,
  contactListData: [],
  status: "",
  contactInfo: undefined,
  twilioToken: undefined,
};
const userData = JSON.parse(localStorage.getItem("user"));
let api_token = "";
if (userData && userData.api_token) {
  api_token = userData.api_token;
}
const token = {
  headers: {
    Authorization: `Bearer ${api_token}`,
  },
};

export const getContactById = createAsyncThunk(
  "contactList/getContactById",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);

    return data;
  }
);

export const importContacts = createAsyncThunk(
  "contactList/importContacts",
  async (body) => {
    const { data } = await axios.post(`${API}${body.url}`, body, {
      headers: {
        Authorization: `Bearer ${api_token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  }
);
export const createContact = createAsyncThunk(
  "contactList/createContact",
  async (body) => {
    const { data } = await axios.put(`${API}${body.url}`, body, token);
    return data;
  }
);
export const updateSingleContact = createAsyncThunk(
  "contactList/updateSingleContact",
  async (body) => {
    const { data } = await axios.put(`${API}${body.url}`, body, token);
    return data;
  }
);
export const contactListDat = createAsyncThunk(
  "contactList/contactListDat",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);

    return data;
  }
);

export const getAllContacts = createAsyncThunk(
  "contactList/getAllContacts",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);

    return data;
  }
);

export const getTwilioToken = createAsyncThunk(
  "contactList/getTwilioToken",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);

    return data;
  }
);
export const deleteContact = createAsyncThunk(
  "contactList/deleteContact",
  async (url) => {
    const { data } = await axios.delete(`${API}${url}`, token);

    return data;
  }
);

export const voiceCall = createAsyncThunk(
  "contactList/voiceCall",
  async (body) => {
    const { data } = await axios.post(`${API}${body.url}`, body, token);
    return data;
  }
);

export const getState = createAsyncThunk(
  "contactList/getState",
  async (body) => {
    const { data } = await axios.post(`${API}${body.url}`, body, token);
    return data;
  }
);

export const updateContact = createAsyncThunk(
  "contactList/updateContact",
  async (body) => {
    const { data } = await axios.put(`${API}${body.url}`, body, token);

    return data;
  }
);

export const getContactsByListId = createAsyncThunk(
  "contactList/getContactsByListId",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);

export const contactListSlice = createSlice({
  name: "contactList",
  initialState,
  reducers: {
    deleTwilioToken: (state, action) => {
      state.twilioToken = undefined;
    },
    deletingContactList: (state, action) => {
      state.contactListData = action.payload;
    },
    deletingContact: (state, action) => {
      state.contactListData.data.data =
        state.contactListData &&
        state.contactListData.data &&
        state.contactListData.data.data.filter(
          (con) => con.id !== action.payload
        );
    },
    saveContactInfo: (state, action) => {
      state.contactInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(importContacts.pending, (state, action) => {
      state.cLoading = true;
    });
    builder.addCase(importContacts.fulfilled, (state, action) => {
      state.cLoading = false;
    });
    builder.addCase(importContacts.rejected, (state, action) => {
      state.cLoading = false;
    });
    builder.addCase(createContact.fulfilled, (state, action) => {
      state.newContact = action.payload;
    });
    builder.addCase(contactListDat.pending, (state, action) => {
      state.contactsLoading = true;
    });
    builder.addCase(contactListDat.fulfilled, (state, action) => {
      state.contactListData = action.payload;
      state.contactsLoading = false;
    });
    builder.addCase(deleteContact.fulfilled, (state, action) => {
      state.status = action.payload;
    });
    builder.addCase(updateContact.fulfilled, (state, action) => {
      state.status = action.payload;
    });
    builder.addCase(updateSingleContact.fulfilled, (state, action) => {
      state.contact = action.payload;
    });
    builder.addCase(getContactsByListId.fulfilled, (state, action) => {
      state.listContacts = action.payload;
    });
    builder.addCase(getContactById.fulfilled, (state, action) => {
      state.contactById = action.payload;
    });
    builder.addCase(getTwilioToken.fulfilled, (state, action) => {
      state.twilioToken = action.payload;
    });
    builder.addCase(getAllContacts.fulfilled, (state, action) => {
      state.allContacts = action.payload;
    });
  },
});

export const {
  saveContactInfo,
  deletingContact,
  deletingContactList,
  deleTwilioToken,
} = contactListSlice.actions;
export default contactListSlice.reducer;
