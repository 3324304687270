import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../config";

const userData = JSON.parse(localStorage.getItem("user"));
let api_token = "";
if (userData && userData.api_token) {
  api_token = userData.api_token;
}
const token = {
  headers: {
    Authorization: `Bearer ${api_token}`,
  },
};

const initialState = {
  loading: false,
  settings: [],
  status: "idle",
};

export const getSettings = createAsyncThunk(
  "setting/getSettings",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);

export const updateSettings = createAsyncThunk(
  "setting/updateSettings",
  async (body) => {
    const { data } = await axios.put(`${API}${body.url}`, body, token);
    return data;
  }
);

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getSettings.fulfilled, (state, action) => {
      state.settings = action.payload;
    });
    builder.addCase(updateSettings.fulfilled, (state, action) => {
      state.status = "success";
    });
  },
});

export default settingSlice.reducer;
