import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import whiteBGlogo from "../../images/logo.png";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { setNewMsgFalse } from "../../redux/conversationSlice";

function Menu() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const { newMsg, conversationURL, notifications } = useSelector(
    (state) => state.root.conversation
  );

  const logout = () => {
    localStorage.removeItem("user");
    toast.success("Logout Successfully");
    window.location = "/login";
  };
  return (
    <>
      <aside id="aside" className="py-5">
        <div className="logo mb-5 px-3">
          <a>
            <img src={whiteBGlogo} className="img-fluid" />
          </a>
        </div>
        <ul className="list-unstyled m-0 p-0 mb-5">
          <li>
            <Link className="p-3" to="/">
              <i className="fa-solid fa-house"></i>
              <span>Dashboard</span>
            </Link>
          </li>
          {/* <li>
            <Link className="p-3" to="/statistics">
              <i className="fa-solid fa-signal"></i>
              <span>Statistics</span>
            </Link>
          </li> */}
          {userData && userData.user_type == "admin" ? (
            <li>
              <Link className="p-3" to="/users">
                <i className="fa-solid fa-user"></i>
                <span>Users</span>
              </Link>
            </li>
          ) : (
            ""
          )}

          <li>
            <Link className="p-3" to="/categories">
              <i className="fa-solid fa-table-cells-large"></i>
              <span>Categories</span>
            </Link>
          </li>
          <li>
            <Link className="p-3" to="/lists">
              <i className="fa-solid fa-list"></i>
              <span>Lists</span>
            </Link>
          </li>
          <li>
            <Link className="p-3" to="/contacts">
              <i className="fa-solid fa-phone"></i>
              <span>Contacts</span>
            </Link>
          </li>

          <li>
            <Link className="p-3" to="/campaigns">
              <i className="fa-regular fa-address-card"></i>
              <span>Campaigns</span>
            </Link>
          </li>
          <li>
            <Link
              onClick={() => dispatch(setNewMsgFalse())}
              className="p-3"
              // to={conversationURL || "/conversation"}
              to="/conversation"
            >
              <div className="d-flex justify-content-between">
                <span>
                  <i className="fa-regular fa-comments"></i>
                  <span>
                    Conversation
                    {newMsg && (
                      <>
                        <sup className="badge badge-pill badge-light">New</sup>
                      </>
                    )}
                  </span>
                </span>
                <sup className="badge badge-pill badge-light">
                  {notifications &&
                    notifications.data >= 1 &&
                    notifications.data}
                </sup>
              </div>
            </Link>
          </li>
          {userData && userData.user_type == "admin" ? (
            <li>
              <Link className="p-3" to="/banWords">
                <i className="fa-solid fa-ban"></i>
                <span>Ban Words</span>
              </Link>
            </li>
          ) : (
            ""
          )}
          <li>
            <Link className="p-3" to="/myProfile">
              <i className="fa-solid fa-user"></i>
              <span>My Profile</span>
            </Link>
          </li>
          {userData && userData.user_type == "admin" ? (
            <li>
              <Link className="p-3" to="/setting">
                <i className="fa-solid fa-gears"></i>
                <span>Setting</span>
              </Link>
            </li>
          ) : (
            ""
          )}

          <li>
            <a className="p-3" onClick={logout}>
              <i className="fa-solid fa-power-off"></i>
              <span>Logout</span>
            </a>
          </li>
        </ul>
      </aside>
      <Toaster position="top-right" />
    </>
  );
}
export default Menu;
