import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PUSHER_APP_KEY, PUSHER_APP_CLUSTER } from "../../config";
import Pusher from "pusher-js";
import {
  getNotifications,
  updateNotifications,
} from "../../redux/campaignSlice";

function NotificationsModal(props) {
  const { id } = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.root.campaign);
  const [show, setShow] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState();
  const [totalNotifications, setTotalNotifications] = useState([]);

  useEffect(() => {
    if (notifications && notifications.data) {
      setTotalNotifications(notifications.data.notifications);
      setUnreadNotifications(notifications.data.total_unread);
    }
  }, [notifications]);

  useEffect(() => {
    const pusher = new Pusher(PUSHER_APP_KEY, {
      cluster: PUSHER_APP_CLUSTER,
      encrypted: true,
    });
    const channel = pusher.subscribe(`campaignNotification-${id}`);
    channel.bind(`g-campaignNotification-${id}`, (pusherData) => {
      if (pusherData) {
        const { data } = pusherData;
        const { notification, total_unread } = data;

        setUnreadNotifications(total_unread);
        setTotalNotifications((totalNotifications) => [
          ...totalNotifications,
          notification,
        ]);
      }
    });
  }, []);

  const handleClose = () => setShow(false);

  const handleNotifications = () => {
    setShow(true);
    if (notifications.data.total_unread > 0)
      dispatch(updateNotifications("notifications/update"));
    dispatch(getNotifications("notifications"));
  };
  return (
    <>
      <Link onClick={handleNotifications}>
        <span style={{ color: "#1f2630" }}>
          <i className="fa-regular fa-bell"></i> Notifications
          <sup className="mx-1">
            {unreadNotifications >= 1 && unreadNotifications}
          </sup>
        </span>
      </Link>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa-regular fa-bell mx-2"></i>Notifications
          </Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{
            maxHeight: "calc(100vh - 180px)",
            overflowY: "auto",
          }}
        >
          <div>
            <ul className="list-group list-unstyled">
              {totalNotifications && totalNotifications.length > 0 ? (
                totalNotifications.map((n) => {
                  return (
                    <li className="list-group-item" key={n.id}>
                      <div className="d-flex justify-content-between">
                        <div className="col">
                          <div>
                            <strong className="px-1">{n.title}</strong>
                          </div>
                          <p className="col px-1">{n.description}</p>
                        </div>

                        <div>
                          <strong>
                            <span className="mx-2">
                              {new Date(n.created_at).getHours() < 10
                                ? "0" + new Date(n.created_at).getHours()
                                : new Date(n.created_at).getHours()}
                              :
                              {new Date(n.created_at).getMinutes() < 10
                                ? "0" + new Date(n.created_at).getMinutes()
                                : new Date(n.created_at).getMinutes()}
                            </span>
                            {moment(n.created_at).format("DD-MMM-YYYY")}
                          </strong>
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <li>
                  <p>There are no notifications available..</p>
                </li>
              )}
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default NotificationsModal;
