import React, { useState } from "react";
import Alert from "../common/Alert";
import Input from "../common/Input";
import logo from "../../images/logo.png";
import { forgetPassword } from "../APIs/userAPI";
import { useNavigate, Link } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const initialState = {
    info: {
      email: "",
    },
    errMsg: "",
    succMsg: "",
  };
  const [values, setValues] = useState(initialState);
  const { info, errMsg, succMsg } = values;

  const handleChange = (e) => {
    const newValues = {
      ...values,
      errMsg: "",
      succMsg: "",
    };
    newValues.info[e.target.name] = e.target.value;
    setValues(newValues);
  };
  const handleClick = async (e) => {
    e.preventDefault();
    const { email } = info;
    try {
      const { data } = await forgetPassword({ email });
      setValues({ ...values, succMsg: data.message });
      setTimeout(() => {
        navigate("/new-password");
      }, 3000);
    } catch (error) {
      setValues({ ...values, errMsg: error.response.data.message });
    }
  };

  return (
    <div>
      <div className="user-pages">
        <div className="logo">
          <a href="/">
            <img src={logo} />
          </a>
        </div>
        <form action="#" className="user-form">
          {errMsg && <Alert message={errMsg} colorClass="danger" />}
          {succMsg && <Alert message={succMsg} colorClass="success" />}
          <h1>
            Forgot
            <br /> your password
          </h1>
          <p className="title-line">
            Please enter the email address associated with your Seektexting
          </p>
          <Input
            label="Email"
            name="email"
            placeholder="abc@gmail.com"
            value={info.email}
            onChange={handleChange}
          />

          <button className="btn btn-primary" onClick={handleClick}>
            Reset It
          </button>
          <p className="bottom-txt">
            Did it just came back to you? <a href="/login">Log In.</a>
          </p>
        </form>
        <div className="footer-txt">
          <p>
            <Link>
              Ⓒ<span>Seek</span> Texting
            </Link>{" "}
            | All right Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
