import React, { useState, useEffect } from "react";
import { signin } from "../APIs/userAPI";
import Input from "../common/Input";
import Alert from "../common/Alert";
import { loading } from "../utils/helper";
import { validation } from "../validations/loginValidator";
import logo from "../../images/logo.png";
import { useCookies } from "react-cookie";
import { useNavigate, Link } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const [cookie, setCookie, removeCookie] = useCookies(["login"]);
  const [isChecked, setIsChecked] = useState(Boolean);
  const [loader, setLoader] = useState();

  const initialState = {
    info: {
      username: "",
      password: "",
    },
    errors: {
      username: "",
      password: "",
    },
    errMsg: "",
    succMsg: "",
  };
  const [values, setValues] = useState(initialState);
  const { info, errors, errMsg, succMsg } = values;

  useEffect(() => {
    if (cookie && cookie.isChecked) {
      setIsChecked(cookie.isChecked);
      setValues({
        ...values,
        info: {
          username: cookie.username,
          password: cookie.password,
        },
      });
    }
  }, []);

  const handleChange = (e) => {
    const newValues = {
      ...values,

      errMsg: "",
      succMsg: "",
    };
    newValues.errors[e.target.name] = "";
    newValues.info[e.target.name] = e.target.value;
    setValues(newValues);
  };
  const handleClick = async (e) => {
    e.preventDefault();
    const { username, password } = info;
    const body = { username, password };
    const errors = validation(body);

    if (errors) {
      return setValues({ ...values, errors: errors });
    }

    try {
      setLoader(true);
      const { data } = await signin(body);
      if (isChecked) {
        setCookie("username", body.username);
        setCookie("password", body.password);
        setCookie("isChecked", true);
      } else {
        removeCookie("username");
        removeCookie("password");
        removeCookie("isChecked");
      }
      if (data.status) {
        localStorage.setItem("user", JSON.stringify(data.data));
        setLoader(false);
        setValues({ ...values, errMsg: "", succMsg: data.message });
        setTimeout(() => {
          navigate("/");
          window.location.reload();
        }, 1000);
      } else {
        setValues({ ...values, errMsg: data.message });
        setLoader(false);
      }
    } catch (error) {
      if (error.response) {
        setLoader(false);
        return setValues({
          ...values,
          errMsg: error.response.data.message,
          succMsg: "",
        });
      }
      if (error.request) {
        setLoader(false);
        setValues({
          ...values,
          errMsg: "No Response From Server..",
          succMsg: "",
        });
      }
    }
  };

  return (
    <div>
      <div className="user-pages">
        <div className="logo">
          <a>
            <img src={logo} />
          </a>
        </div>
        <form ac className="user-form">
          {errMsg && <Alert message={errMsg} colorClass="danger" />}
          {succMsg && <Alert message={succMsg} colorClass="success" />}
          <h1>Welcome Back</h1>
          <p className="title-line">text marketing platform</p>
          <div className="text-center">{loader && loading()}</div>
          <Input
            error={errors.username}
            label="Email"
            name="username"
            placeholder="abc@gmail.com"
            value={info.username}
            onChange={handleChange}
            required
          />
          <Input
            error={errors.password}
            type="password"
            label="Password"
            name="password"
            placeholder="Min. 6 Characters"
            value={info.password}
            onChange={handleChange}
            required
          />
          <div className="form-group forgot-password-line">
            <div className="checkbox-sec">
              <input
                name="isChecked"
                type="checkbox"
                checked={isChecked}
                id="checkbox"
                onChange={() => setIsChecked(!isChecked)}
              />
              <label htmlFor="checkbox">Remember Me</label>
            </div>
            {/* <a className="forgot-link" href="/forgot-password">
              Forgot Password?
            </a> */}
          </div>
          <button className="btn btn-primary" onClick={handleClick}>
            Login
          </button>
          {/* <p className="bottom-txt">
            Not registered yet? <a href="/signup">Create an Account</a>
          </p> */}
        </form>
        <div className="footer-txt">
          <p>
            <Link>
              Ⓒ <span>Seek</span> Texting
            </Link>{" "}
            | All right Reserved.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
