import React from "react";

function Alert({ colorClass, message }) {
  return (
    <div className={`alert alert-${colorClass}`} role="alert">
      {message}
    </div>
  );
}

export default Alert;
