import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../config";

const userData = JSON.parse(localStorage.getItem("user"));
let api_token = "";
if (userData && userData.api_token) {
  api_token = userData.api_token;
}
const token = {
  headers: {
    Authorization: `Bearer ${api_token}`,
  },
};

const initialState = {
  conversationURL: "",
  chatsURL: "",
  getChatsURL: "",
  chatsLoading: false,
  messagesLoading: false,
  newMsg: false,
  conversation: [],
  singleConversation: [],
  chats: [],
  totalChats: undefined,
  status: "idle",
  chatID: "",
  notifications: undefined,
  message: {},
  chatsFlag: undefined,
};

export const getChats = createAsyncThunk(
  "conversation/getChats",
  async (body) => {
    const { data } = await axios.post(`${API}${body.url}`, body, token);
    return data;
  }
);

export const getConversation = createAsyncThunk(
  "conversation/getConversation",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);

export const getchatWithContact = createAsyncThunk(
  "conversation/getchatWithContact",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);

export const getTotalMsgNotifications = createAsyncThunk(
  "conversation/getTotalMsgNotifications",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);

export const updateUnreadMessages = createAsyncThunk(
  "conversation/updateUnreadMessages",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);

export const sendMessage = createAsyncThunk(
  "conversation/sendMessage",
  async (body) => {
    const { data } = await axios.post(`${API}${body.url}`, body, token);
    return data;
  }
);

export const addSingleChat = createAsyncThunk(
  "conversation/addSingleChat",
  async (body) => {
    const { data } = await axios.post(`${API}${body.url}`, body, token);
    return data;
  }
);

export const viewSingleChat = createAsyncThunk(
  "conversation/viewSingleChat",
  async (body) => {
    const { data } = await axios.post(`${API}${body.url}`, body, token);
    return data;
  }
);

export const deleteChat = createAsyncThunk(
  "conversation/deleteChat",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);

export const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    setChatsFlag: (state, action) => {
      state.chatsFlag = action.payload;
    },
    saveMessage: (state, action) => {
      state.message = action.payload;
    },
    saveChatID: (state, action) => {
      state.chatID = action.payload;
    },
    updateUnreadMessage: (state, action) => {
      state.totalChats &&
        state.totalChats.map((chat) => {
          if (chat.id == action.payload) chat.unread = 0;
        });
    },
    removeTotalChats: (state, action) => {
      state.totalChats = [];
    },
    removeChat: (state, action) => {
      state.totalChats =
        state.totalChats &&
        state.totalChats.filter((chat) => chat.id !== action.payload);
    },
    removeChats: (state, action) => {
      state.chats.data.data = [];
    },
    saveTotalChats: (state, action) => {
      state.totalChats = action.payload;
    },
    setNewMsgTrue: (state, action) => {
      state.newMsg = true;
    },
    setNewMsgFalse: (state, action) => {
      state.newMsg = false;
    },
    removeGeneralChat: (state, action) => {
      state.conversation.data = [];
    },

    saveConversationURL: (state, action) => {
      state.conversationURL = action.payload;
    },
    saveChatsURL: (state, action) => {
      state.chatsURL = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConversation.fulfilled, (state, action) => {
      state.conversation = action.payload;
    });
    builder.addCase(getChats.pending, (state, action) => {
      state.chatsLoading = false;
      state.chats = action.payload;
    });
    builder.addCase(getChats.fulfilled, (state, action) => {
      state.chats = action.payload;
      state.chatsLoading = true;
    });
    builder.addCase(getChats.rejected, (state, action) => {
      state.chatsLoading = false;
      state.chats = action.payload;
    });
    builder.addCase(addSingleChat.fulfilled, (state, action) => {
      state.chats = action.payload;
    });
    builder.addCase(viewSingleChat.fulfilled, (state, action) => {
      state.chats = action.payload;
    });
    builder.addCase(getchatWithContact.pending, (state, action) => {
      state.messagesLoading = false;
      state.singleConversation = action.payload;
    });
    builder.addCase(getchatWithContact.fulfilled, (state, action) => {
      state.messagesLoading = true;
      state.singleConversation = action.payload;
    });
    builder.addCase(getchatWithContact.rejected, (state, action) => {
      state.messagesLoading = false;
      state.singleConversation = action.payload;
    });
    builder.addCase(sendMessage.fulfilled, (state, action) => {
      state.status = "success";
    });
    builder.addCase(getTotalMsgNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload;
    });
  },
});

export const {
  setChatsFlag,
  saveMessage,
  saveChatID,
  saveTotalChats,
  removeTotalChats,
  setNewMsgTrue,
  setNewMsgFalse,
  removeGeneralChat,
  saveConversationURL,
  saveChatsURL,
  updateUnreadMessage,
  removeChat,
  removeChats,
} = conversationSlice.actions;
export default conversationSlice.reducer;
