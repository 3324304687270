import React from "react";
import logo from "../../images/logo.png";
import emailImg from "../../images/email-img.png";
import { useNavigate } from "react-router-dom";

const VerifyEmail = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="user-pages">
        <div className="logo">
          <a href="#">
            <img src={logo} />
          </a>
        </div>
        <form action="#" className="user-form">
          <div className="message-img">
            <img src={emailImg} />
          </div>
          <h1>Verify your email</h1>
          <p className="bottom-txt">
            You've entered <a href="#">abc@gmail.com</a> as the email address
            for your account.
            <br /> Please verify this email address by clicking button below.{" "}
          </p>
          <input
            onClick={() => {
              navigate("/new-password");
            }}
            type="submit"
            name=""
            value="Verify Email"
          />
        </form>
        <div className="footer-txt">
          <p>
            <a href="#">
              Ⓒ<span>Seek</span> Texting
            </a>{" "}
            | All right Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
