import React, { useState } from "react";
import { changePassword } from "../APIs/userAPI";
import Input from "../common/Input";
import Alert from "../common/Alert";
import { useNavigate, Link } from "react-router-dom";

const NewPassword = () => {
  const navigate = useNavigate();

  const initialState = {
    info: {
      password: "",
      confirmPassword: "",
    },
    errMsg: "",
    succMsg: "",
  };
  const [values, setValues] = useState(initialState);
  const { info, errMsg, succMsg } = values;

  const handleChange = (e) => {
    const newValues = {
      ...values,
      errMsg: "",
      succMsg: "",
    };
    newValues.info[e.target.name] = e.target.value;
    setValues(newValues);
  };
  const handleClick = async (e) => {
    e.preventDefault();
    const { password, confirmPassword } = info;
    const body = { password, confirmPassword };
    try {
      const { data } = await changePassword(body);
      setValues({ ...values, succMsg: data.message });
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      setValues({ ...values, errMsg: error.response.data.message });
    }
  };

  return (
    <div>
      <div className="user-pages">
        <div className="logo">
          <a href="/">
            <img src="images/logo.png" />
          </a>
        </div>
        <form action="#" className="user-form">
          {errMsg && <Alert message={errMsg} colorClass="danger" />}
          {succMsg && <Alert message={succMsg} colorClass="success" />}
          <h1>Set new password</h1>
          <p className="title-line">
            This will reset your password and it cannot be undone.
          </p>
          <Input
            label="Password"
            name="password"
            placeholder="Min. 8 Characters"
            value={info.password}
            onChange={handleChange}
          />
          <Input
            label="Confirm Password"
            name="confirmPassword"
            placeholder="Confirm Password"
            value={info.confirmPassword}
            onChange={handleChange}
          />
          <input onClick={handleClick} type="submit" value="Update Password" />
          <p className="bottom-txt">
            Did it just came back to you? <a href="/login">Log In.</a>
          </p>
        </form>
        <div className="footer-txt">
          <p>
            <Link>
              Ⓒ<span>Seek</span> Texting
            </Link>{" "}
            | All right Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
