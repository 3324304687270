import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "../redux/userSlice";
import subscribersReducer from "../redux/subscribersSlice";
import categoriesReducer from "../redux/categoriesSlice";
import listsReducer from "../redux/listsSlice";
import contactListReducer from "../redux/contactListSlice";
import campaignReducer from "../redux/campaignSlice";
import banWordsReducer from "../redux/banWordsSlice";
import settingReducer from "../redux/settingSlice";
import conversationSlice from "../redux/conversationSlice";
import dashboardSlice from "../redux/dashboardSlice";

export default combineReducers({
  user: userReducer,
  subscribers: subscribersReducer,
  categories: categoriesReducer,
  lists: listsReducer,
  contactList: contactListReducer,
  campaign: campaignReducer,
  banWords: banWordsReducer,
  setting: settingReducer,
  conversation: conversationSlice,
  dashboard: dashboardSlice,
});
