import React from "react";

const NameInitialsLogo = ({ name }) => {
  const initials = name
    .split(" ")
    .slice(0, 2)
    .map((name) => name[0])
    .join("")
    .toUpperCase();

  return (
    <div>
      <div
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title={name}
        id="profileImage"
      >
        {initials}
      </div>
    </div>
  );
};

export default NameInitialsLogo;
