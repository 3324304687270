import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/login/Login";
import Signup from "./components/signup/Signup";
import DefaultLayout from "./layout/DefaultLayout";
import ForgotPassword from "./components/password/ForgotPassword";
import NewPassword from "./components/password/NewPassword";
import VerifyEmail from "./components/verification/VerifyEmail";
import Protected from "./auth/Protected";
import Pusher from "pusher-js";
import { useDispatch } from "react-redux";
import {
  setNewMsgFalse,
  setNewMsgTrue,
  saveConversationURL,
  getTotalMsgNotifications,
  saveMessage,
} from "./redux/conversationSlice";
import { getNotifications } from "./redux/campaignSlice";
import { PUSHER_APP_KEY, PUSHER_APP_CLUSTER } from "./config";
import { getTextLimit } from "./redux/dashboardSlice";

function App() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();

  useEffect(() => {
    if (userData) {
      dispatch(getTotalMsgNotifications("chat/getNotifications"));
      dispatch(getNotifications("notifications"));
      dispatch(getTextLimit("chat/userDailyTxtLimit"));
    }
  });

  useEffect(() => {
    const pusher = new Pusher(PUSHER_APP_KEY, {
      cluster: PUSHER_APP_CLUSTER,
      encrypted: true,
    });
    const channel = pusher.subscribe(`chat-${userData && userData.id}`);
    channel.bind(`g-chat-${userData && userData.id}`, (data) => {
      if (data) {
        dispatch(saveMessage(data.data));
        if (data && data.data && data.data.type == "subscriber") {
          // dispatch(saveConversationURL(data.data.url && data.data.url));
          dispatch(setNewMsgTrue());
        }
      }
    });
  }, []);

  useEffect(() => {
    const pusher = new Pusher(PUSHER_APP_KEY, {
      cluster: PUSHER_APP_CLUSTER,
      encrypted: true,
    });
    const channel = pusher.subscribe("chat");
    channel.bind("g-chat", (data) => {
      if (data && data.data && data.data.from_id) {
        dispatch(saveMessage(data.data));
        // dispatch(saveConversationURL(data.url && data.url));
        data.data.from_id == userData.id
          ? dispatch(setNewMsgFalse())
          : dispatch(setNewMsgTrue());
      }
    });
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          {/* <Route exact path="/signup" element={<Signup />} /> */}
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/new-password" element={<NewPassword />} />
          <Route exact path="/verify-email" element={<VerifyEmail />} />

          <Route
            path="*"
            element={<Protected children={<DefaultLayout />} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
