import React from "react";

const VoiceCall = React.lazy(() => import("./components/common/VoiceCall"));
const Dashboard = React.lazy(() => import("./components/dashboard/Dashboard"));
const Statistics = React.lazy(() =>
  import("./components/statistics/Statistics")
);
const Contacts = React.lazy(() => import("./components/contacts/Contacts"));
const Categories = React.lazy(() =>
  import("./components/categories/Categories")
);
const Campaigns = React.lazy(() => import("./components/campaigns/Campaigns"));
const CreateCompaignInfo = React.lazy(() =>
  import("./components/campaigns/components/campaignInfo/CreateCompaignInfo")
);
const CreateCompaignContent = React.lazy(() =>
  import(
    "./components/campaigns/components/campaignContent/CreateCampaignContent"
  )
);
const CreateCompaignReview = React.lazy(() =>
  import(
    "./components/campaigns/components/campaignReview/CreateCampaignReview"
  )
);
const ReturnToCampaigns = React.lazy(() =>
  import("./components/campaigns/ReturnToCampaigns")
);
const UpdateCompaignInfo = React.lazy(() =>
  import("./components/campaigns/components/campaignUpdate/UpdateCampaignInfo")
);
const UpdateCompaignContent = React.lazy(() =>
  import(
    "./components/campaigns/components/campaignUpdate/UpdateCampaignContent"
  )
);
const UpdateCompaignReview = React.lazy(() =>
  import(
    "./components/campaigns/components/campaignUpdate/UpdateCampaignReview"
  )
);

const Conversation = React.lazy(() =>
  import("./components/conversation/Conversation")
);

const BanWords = React.lazy(() => import("./components/banWords/BanWords"));
const Settings = React.lazy(() => import("./components/settings/Settings"));
const Users = React.lazy(() => import("./components/users/Users"));
const Lists = React.lazy(() => import("./components/lists/Lists"));
const Logout = React.lazy(() => import("./components/logout/Logout"));
const Page404 = React.lazy(() => import("./components/page404/Page404"));

const routes = [
  { path: "*", element: Page404 },
  { exact: true, path: "/", name: "Dashboard", element: Dashboard },
  {
    exact: true,
    path: "/campaigns/statistics/:campaignId",
    name: "Statistics",
    element: Statistics,
  },

  {
    path: "/contacts/:user_id?",
    name: "Contact List",
    element: Contacts,
  },

  {
    exact: true,
    path: "/setting",
    name: "Settings",
    element: Settings,
  },
  {
    exact: true,
    path: "/contacts/list/:list_id",
    name: "Contact List",
    element: Contacts,
  },
  {
    path: "/categories/:user_id?",
    name: "Categories List",
    element: Categories,
  },
  {
    path: "/campaigns",
    name: "Campaigns List",
    element: Campaigns,
  },
  {
    path: "/campaigns/update/:campaign_id",
    name: "Update Campaigns Info",
    element: UpdateCompaignInfo,
  },
  {
    path: "/update-campaign-review",
    name: "Update Campaigns Review",
    element: UpdateCompaignReview,
  },
  {
    path: "/campaign-update-content",
    name: "Update Campaigns Content",
    element: UpdateCompaignContent,
  },
  {
    path: "/create-campaign-info",
    name: "Campaigns List",
    element: CreateCompaignInfo,
  },
  {
    path: "/create-campaign-content",
    name: "Campaigns List",
    element: CreateCompaignContent,
  },
  {
    path: "/create-campaign-review",
    name: "Campaigns List",
    element: CreateCompaignReview,
  },
  {
    path: "/returnToCampaigns",
    name: "Return To Campaigns",
    element: ReturnToCampaigns,
  },

  {
    path: "/conversation/:chat_id?",
    name: "Conversation",
    element: Conversation,
  },

  { path: "/voice-call", name: "Voice", element: VoiceCall },

  { path: "/users/:user_id?", name: "Users", element: Users },
  { path: "/banWords", name: "Ban Words", element: BanWords },
  { path: "/myProfile", name: "My Profile", element: Users },
  { path: "/lists/:user_id?", name: "Lists", element: Lists },
  { exact: true, path: "/logout", name: "Logout", element: Logout },
];

export default routes;
