import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import routes from "../routes";

const AppContent = () => {
  const Loading = () => (
    <div className="spinner-border text-primary mt-3" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {routes.map((route, idx) => {
          return (
            route.element && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={<route.element />}
              />
            )
          );
        })}
        {/* <Route path="/" element={<Navigate to="dashboard" replace />} />
      <Route path="*" element={<Navigate to="404" replace />} /> */}
      </Routes>
    </Suspense>
  );
};

export default AppContent;
// export default React.memo(AppContent);
