import Joi from "joi";
import tlds from "tlds";
export const validation = (data) => {
  const schema = Joi.object({
    first_name: Joi.string()
      .regex(/^[a-zA-Z ]+$/)
      .min(3)
      .regex(/[!,.&*{/}$\(\)<>]/, { invert: true })
      .required()
      .messages({
        "any.required": `First Name cannot be an empty field`,
        "string.empty": `First Name cannot be an empty field`,
        "string.min": `First Name should have a minimum length of {#limit}`,
        "string.pattern.base": "Only alphabets are allowed",
        "string.pattern.invert.base": "These characters are not allowed",
      }),
    last_name: Joi.string()
      .regex(/^[a-zA-Z ]+$/)
      .min(3)
      .regex(/[!,.&*{/}$\(\)<>]/, { invert: true })
      .required()
      .messages({
        "string.empty": `Last Name cannot be an empty field`,
        "any.required": `Last Name cannot be an empty field`,
        "string.min": `Last Name should have a minimum length of {#limit}`,
        "string.pattern.base": "Only alphabets are allowed",
        "string.pattern.invert.base": "These characters are not allowed",
      }),
    email: Joi.string()
      .email({
        minDomainSegments: 2,
        tlds: { allow: tlds },
      })
      .trim()
      .lowercase()
      .custom((value, helpers) => {
        const [localPart] = value.split("@");

        if (/^(?=.*[a-zA-Z])[a-zA-Z\d.]+$/.test(localPart)) {
          return value;
        }

        return helpers.error("any.invalid");
      })
      .required()
      .messages({
        "any.required": `Email cannot be an empty field`,
        "any.invalid": `Email must be a valid email`,
        "string.empty": `Email cannot be an empty field`,
        "string.email": `Email must be a valid email`,
        "string.pattern.base":
          "Email must contain at least 1 alphabetical character",
      }),
    password: Joi.string().min(6).required().messages({
      "string.empty": `Password cannot be an empty field`,
      "any.required": `Password cannot be an empty field`,
      "string.min": `Password should have a minimum length of {#limit}`,
    }),
    contact_number: Joi.string()
      // .regex(/^\+[0-9]/, {
      //   invert: false,
      // })
      .min(10)
      .max(15)
      .required()
      .messages({
        "string.empty": `Twilio Number cannot be an empty field`,
        "string.base": `Twilio Number must be a number`,
        "any.required": `Twilio Number cannot be an empty field`,
        "string.min": `Twilio Number should have a minimum length of {#limit}`,
        "string.max": `Twilio Number should have maximum length of 15 numbers`,
        "string.pattern.base":
          "Contact does not match with pattern +12345612345",
      }),
    call_forwarding_number: Joi.string()
      // .regex(/^\+[0-9]/, {
      //   invert: false,
      // })
      .min(10)
      .max(15)
      .required()
      .messages({
        "string.empty": `Phone Number cannot be an empty field`,
        "string.base": `Phone Number must be a number`,
        "any.required": `Phone Number cannot be an empty field`,
        "string.min": `Phone Number should have a minimum length of {#limit}`,
        "string.max": `Phone Number should have maximum length of 15 numbers`,
        "string.pattern.base":
          "Contact does not match with pattern +12345612345",
      }),

    daily_text_limit: Joi.number().max(10000).required().messages({
      "number.base": `Daily Text Limit must be a number`,
      "number.empty": `Daily Text Limit cannot be an empty field`,
      "number.max": `Daily Text Limit should have a maximum limit of {#limit}`,
    }),
    text_length: Joi.number().max(160).required().messages({
      "number.base": `Text Length must be a number`,
      "number.empty": `Text Length cannot be an empty field`,
      "number.max": `Text Length should have a maximum length of {#limit}`,
    }),
  });
  const { error } = schema.validate(data, { abortEarly: false });
  // console.log(
  //   error.details.map((errDetail) => errDetail.type),
  //   error
  // );
  if (!error) return null;
  const errors = {};
  for (let item of error.details) errors[item.path[0]] = item.message;
  return errors;
};
