import React from "react";

function Input({
  name,
  label,
  placeholder,
  value,
  onChange,
  autoFocus,
  error,
  type = "text",
  required,
}) {
  return (
    <div className={`form-group ${required ? "required" : ""}`}>
      <label className="control-label" htmlFor={name}>
        {label}
      </label>
      <input
        autoFocus={autoFocus}
        name={name}
        onChange={onChange}
        value={value}
        type={type}
        id={name}
        placeholder={placeholder}
      />
      {/* {error && <div className="alert alert-danger">{error}</div>} */}
      {error && <p className="text-danger mt-1">{error}</p>}
    </div>
  );
}

export default Input;
