import { Link } from "react-router-dom";
import profileImg from "../../images/user.png";
import NameInitialsLogo from "../common/NameInitialsLogo";
import NotificationsModal from "../notifications/Notifications";
import { useSelector } from "react-redux";
import NavDropdown from "react-bootstrap/NavDropdown";
import toast, { Toaster } from "react-hot-toast";

// import profileImg from "../../images/avatar.png";

import $ from "jquery";
const userData = JSON.parse(localStorage.getItem("user"));

function Header() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const { textLimit } = useSelector((state) => state.root.dashboard);

  $(document).ready(function () {
    $(".menu-btn").click(function () {
      $("body").toggleClass("menu-open");
    });
  });

  const logout = () => {
    localStorage.removeItem("user");
    toast.success("Logout Successfully");
    window.location = "/login";
  };

  return (
    <header className="content-header d-flex justify-content-between align-items-center ps-3 ps-lg-4">
      <div className="d-flex align-items-center">
        <a className="menu-btn">
          <i className="fa-solid fa-bars"></i>
        </a>
      </div>
      <div className="rightalign d-flex align-items-center">
        <NotificationsModal />

        {textLimit && (
          <div className="user p-3">
            Daily Text Limit Usage : {textLimit && textLimit.data}
          </div>
        )}

        <div className="user">
          <NavDropdown
            id="nav-dropdown-dark-example"
            title={
              <span
                style={{
                  color: "#1f2630",
                  border: "10px solid rgba(43, 79, 132, 0.0)",
                }}
                className="d-inline-flex align-items-center"
              >
                <NameInitialsLogo
                  name={`${userData.first_name} ${userData.last_name}`}
                />
                <p className="align-text-bottom my-0 mx-2">
                  {userData.first_name} {userData.last_name}
                </p>
              </span>
            }
          >
            <NavDropdown.Item as={Link} to="myProfile">
              Profile
            </NavDropdown.Item>
            {userData && userData.user_type == "admin" && (
              <NavDropdown.Item as={Link} to="setting">
                Settings
              </NavDropdown.Item>
            )}
            {/* <NavDropdown.Divider /> */}
            <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
          </NavDropdown>
        </div>
      </div>
    </header>
  );
}
export default Header;
