import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../config";
const userData = JSON.parse(localStorage.getItem("user"));
let api_token = "";
if (userData && userData.api_token) {
  api_token = userData.api_token;
}
const token = {
  headers: {
    Authorization: `Bearer ${api_token}`,
  },
};

export const createBanWords = createAsyncThunk(
  "banWords/createBanWords",
  async (body) => {
    const { data } = await axios.put(`${API}${body.url}`, body, token);
    return data;
  }
);

export const updateBanWords = createAsyncThunk(
  "banWords/updateBanWords",
  async (body) => {
    const { data } = await axios.put(`${API}${body.url}`, body, token);
    return data;
  }
);
export const updateBanWordStatus = createAsyncThunk(
  "banWords/updateBanWordsStatus",
  async (body) => {
    const { data } = await axios.put(`${API}${body.url}`, body, token);
    return data;
  }
);

export const banWordsList = createAsyncThunk(
  "banWords/banWordsList",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);

export const deleteBanWords = createAsyncThunk(
  "banWords/deleteBanWords",
  async (url) => {
    const { data } = await axios.delete(`${API}${url}`, token);
    return data;
  }
);

const initialState = {
  loading: false,
  banWords: [],
  status: undefined,
  newBanWord: undefined,
  updatedBanWord: undefined,
};

export const banWordsSlice = createSlice({
  name: "banWords",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(banWordsList.fulfilled, (state, action) => {
      state.banWords = action.payload;
    });
    builder.addCase(createBanWords.fulfilled, (state, action) => {
      state.newBanWord = action.payload;
    });
    builder.addCase(updateBanWords.fulfilled, (state, action) => {
      state.updatedBanWord = action.payload;
    });
    builder.addCase(updateBanWordStatus.fulfilled, (state, action) => {
      state.status = action.payload;
    });
    builder.addCase(deleteBanWords.fulfilled, (state, action) => {});
  },
});

export default banWordsSlice.reducer;
