import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../config";

const initialState = {
  loading: false,
  emailHtml: undefined,
  campaigns: [],
  campaign: {},
  campaignStatus: "",
  campaignType: "email",
  campainInfoData: {},
  campainContentData: {},
  newCampaign: undefined,
  notifications: undefined,
};

const userData = JSON.parse(localStorage.getItem("user"));
let api_token = "";
if (userData && userData.api_token) {
  api_token = userData.api_token;
}
const token = {
  headers: {
    Authorization: `Bearer ${api_token}`,
  },
};
export const campaignsList = createAsyncThunk(
  "campaign/campaignsList",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);
export const getCamapaignById = createAsyncThunk(
  "campaign/getCamapaignById",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);
export const createCampaign = createAsyncThunk(
  "campaign/createCampaign",
  async (body) => {
    const { data } = await axios.put(`${API}${body.url}`, body, token);
    return data;
  }
);
export const deleteCampaign = createAsyncThunk(
  "campaign/deleteCampaign",
  async (url) => {
    const { data } = await axios.delete(`${API}${url}`, token);
    return data;
  }
);
export const getNotifications = createAsyncThunk(
  "campaign/getNotifications",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);
export const updateNotifications = createAsyncThunk(
  "campaign/updateNotifications",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);

export const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    saveEmailHtml: (state, action) => {
      state.emailHtml = action.payload;
    },
    saveCampaignType: (state, action) => {
      state.campaignType = action.payload;
    },
    saveCampaignInfoData: (state, action) => {
      state.campainInfoData = action.payload;
    },
    saveCampaignContentData: (state, action) => {
      state.campainContentData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(campaignsList.fulfilled, (state, action) => {
      state.campaigns = action.payload;
    });
    builder.addCase(createCampaign.fulfilled, (state, action) => {
      state.newCampaign = action.payload;
    });
    builder.addCase(getCamapaignById.fulfilled, (state, action) => {
      state.campaign = action.payload;
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload;
    });
  },
});

export const {
  saveEmailHtml,
  saveCampaignType,
  saveCampaignInfoData,
  saveCampaignContentData,
} = campaignSlice.actions;

export default campaignSlice.reducer;
