import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../config";

const userData = JSON.parse(localStorage.getItem("user"));
let api_token = "";
if (userData && userData.api_token) {
  api_token = userData.api_token;
}
const token = {
  headers: {
    Authorization: `Bearer ${api_token}`,
  },
};

const initialState = {
  loading: false,
  previousMonthContacts: [],
  deliveryRate: undefined,
  campaignRate: undefined,
  spamRate: undefined,
  unsubRate: undefined,
  campaignReport: undefined,
  campaignDailyReport: undefined,
  totalSMS: undefined,
  textLimit: undefined,
  deliveredSMS: undefined,
  status: "idle",
};

export const getTotalSMS = createAsyncThunk(
  "dashboard/getTotalSMS",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);

export const getTextLimit = createAsyncThunk(
  "dashboard/getTextLimit",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);

export const getDeliveredSMS = createAsyncThunk(
  "dashboard/getDeliveredSMS",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);

export const getPreviousMonthContacts = createAsyncThunk(
  "dashboard/getPreviousMonthContacts",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);

export const getDeliveryRate = createAsyncThunk(
  "dashboard/getDeliveryRate",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);

export const getCampaignRate = createAsyncThunk(
  "dashboard/getCampaignRate",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);

export const getSpamRate = createAsyncThunk(
  "dashboard/getSpamRate",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);

export const getUnsubRate = createAsyncThunk(
  "dashboard/getUnsubRate",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);

export const getCampaignReport = createAsyncThunk(
  "dashboard/getCampaignReport",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);

export const getCampaignDailyReport = createAsyncThunk(
  "dashboard/getCampaignDailyReport",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPreviousMonthContacts.fulfilled, (state, action) => {
      state.previousMonthContacts = action.payload;
    });
    builder.addCase(getDeliveryRate.fulfilled, (state, action) => {
      state.deliveryRate = action.payload;
    });
    builder.addCase(getCampaignRate.fulfilled, (state, action) => {
      state.campaignRate = action.payload;
    });
    builder.addCase(getSpamRate.fulfilled, (state, action) => {
      state.spamRate = action.payload;
    });
    builder.addCase(getUnsubRate.fulfilled, (state, action) => {
      state.unsubRate = action.payload;
    });
    builder.addCase(getCampaignReport.fulfilled, (state, action) => {
      state.campaignReport = action.payload;
    });
    builder.addCase(getCampaignDailyReport.fulfilled, (state, action) => {
      state.campaignDailyReport = action.payload;
    });
    builder.addCase(getTotalSMS.fulfilled, (state, action) => {
      state.totalSMS = action.payload;
    });
    builder.addCase(getDeliveredSMS.fulfilled, (state, action) => {
      state.deliveredSMS = action.payload;
    });
    builder.addCase(getTextLimit.fulfilled, (state, action) => {
      state.textLimit = action.payload;
    });
  },
});

// export const {} = dashboardSlice.actions;
export default dashboardSlice.reducer;
