import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../config";

const initialState = {
  totalListContacts: [],
  listData: undefined,
  newList: undefined,
  listsLoading: false,
  listsData: [],
  list: undefined,
  status: "",
};
const userData = JSON.parse(localStorage.getItem("user"));
let api_token = "";
if (userData && userData.api_token) {
  api_token = userData.api_token;
}
const token = {
  headers: {
    Authorization: `Bearer ${api_token}`,
  },
};
export const createList = createAsyncThunk("list/createList", async (body) => {
  const { data } = await axios.put(`${API}${body.url}`, body, token);
  return data;
});
export const listDat = createAsyncThunk("list/listsData", async (url) => {
  const { data } = await axios.get(`${API}${url}`, token);

  return data;
});
export const getListById = createAsyncThunk("list/getListById", async (url) => {
  const { data } = await axios.get(`${API}${url}`, token);
  return data;
});

export const getTotalListContacts = createAsyncThunk(
  "list/getTotalListContacts",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);

export const exportList = createAsyncThunk("list/exportList", async (body) => {
  const { data } = await axios.post(`${API}${body.url}`, body, token);
  return data;
});

export const updateList = createAsyncThunk("list/updateList", async (body) => {
  const { data } = await axios.put(`${API}${body.url}`, body, token);
  return data;
});
export const deleteList = createAsyncThunk("list/deleteList", async (url) => {
  const { data } = await axios.delete(`${API}${url}`, token);
  return data;
});
export const updateListStatus = createAsyncThunk(
  "list/updateListStatus",
  async (body) => {
    const { data } = await axios.put(`${API}${body.url}`, body, token);
    return data;
  }
);

export const listsSlice = createSlice({
  name: "list",
  initialState,
  reducers: {
    setListData: (state, action) => {
      state.listData = action.payload;
    },
    deletingList: (state, action) => {
      state.listsData.data.data =
        state.listsData &&
        state.listsData.data &&
        state.listsData.data.data.filter((lis) => lis.id !== action.payload);
    },
    changingListStatus: (state, action) => {
      // const { id, newStatus } = action.payload;
      // const filteredLists =
      //   state.listsData &&
      //   state.listsData.data &&
      //   state.listsData.data.data.filter((lis) => lis.id !== id);
      // console.log(filteredLists);
      // const list = { ...state.listsData.data.data[id] };
      // list.status = newStatus;
      // filteredLists.push(list);
      // state.listsData.data.data = filteredLists;
      // state.listsData.data.data = state.listsData.data.data.map((item) => {
      //   if (item.id === id) {
      //     const newItem = { ...item };
      //     newItem.status = newStatus;
      //     // return { ...item, item[status]: newStatus };
      //   }
      // });
      /////////////////////////////////////////////////////////////////
      // const modifiedList = { ...state.listsData.data.data[id] };
      // console.log(modifiedList);
      // state.listsData.data.data[id] = {};
      // state.listsData.data.data =
      //   state.listsData &&
      //   state.listsData.data &&
      //   state.listsData.data.data.map((lis, i) => {
      //     console.log(lis);
      //     if (lis.id === id) return (lis.status = newStatus);
      //   });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createList.fulfilled, (state, action) => {
      state.newList = action.payload;
    });
    builder.addCase(listDat.pending, (state, action) => {
      state.listsLoading = true;
    });
    builder.addCase(listDat.fulfilled, (state, action) => {
      state.listsData = action.payload;
      state.listsLoading = false;
    });
    builder.addCase(deleteList.fulfilled, (state, action) => {
      state.status = action.payload;
    });
    builder.addCase(updateList.fulfilled, (state, action) => {
      state.list = action.payload;
    });
    builder.addCase(updateListStatus.fulfilled, (state, action) => {
      state.status = action.payload;
    });
    builder.addCase(getTotalListContacts.fulfilled, (state, action) => {
      state.totalListContacts = action.payload;
    });
  },
});
export const { setListData, deletingList, changingListStatus } =
  listsSlice.actions;
export default listsSlice.reducer;
