import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../config";
const initialState = {
  newCategory: undefined,
  loading: false,
  category: undefined,
  categories: [],
  categoryStatus: "",
};
const userData = JSON.parse(localStorage.getItem("user"));
let api_token = "";
if (userData && userData.api_token) {
  api_token = userData.api_token;
}
const token = {
  headers: {
    Authorization: `Bearer ${api_token}`,
  },
};
export const createCategory = createAsyncThunk(
  "categories/createCategory",
  async (body) => {
    const { data } = await axios.put(`${API}${body.url}`, body, token);
    return data;
  }
);
export const updateCategory = createAsyncThunk(
  "categories/updateCategory",
  async (body) => {
    const { data } = await axios.put(`${API}${body.url}`, body, token);
    return data;
  }
);
export const categoriesList = createAsyncThunk(
  "categories/categoriesList",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);

    return data;
  }
);
export const deleteCategory = createAsyncThunk(
  "categories/deleteCategory",
  async (url) => {
    const { data } = await axios.delete(`${API}${url}`, token);
    return data;
  }
);
export const updateCategoryStatus = createAsyncThunk(
  "categories/updateCategoryStatus",
  async (body) => {
    const { data } = await axios.put(`${API}${body.url}`, body, token);
    return data;
  }
);

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(createCategory.fulfilled, (state, action) => {
      state.newCategory = action.payload;
    });
    builder.addCase(categoriesList.fulfilled, (state, action) => {
      state.categories = action.payload;
    });
    builder.addCase(deleteCategory.fulfilled, (state, action) => {
      state.categoryStatus = action.payload;
    });
    builder.addCase(updateCategoryStatus.fulfilled, (state, action) => {
      state.categoryStatus = action.payload;
    });
    builder.addCase(updateCategory.fulfilled, (state, action) => {
      state.category = action.payload;
    });
  },
});
export default categoriesSlice.reducer;
