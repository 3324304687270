import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../config";
const initialState = {
  loading: false,
  subscribers: [],
  subscriber: undefined,
  updateUserStatus: "",
};
const userData = JSON.parse(localStorage.getItem("user"));
let api_token = "";
if (userData && userData.api_token) {
  api_token = userData.api_token;
}
const token = {
  headers: {
    Authorization: `Bearer ${api_token}`,
  },
};

export const getSubscriber = createAsyncThunk(
  "subscribers/getSubscriber",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);

export const subscribersList = createAsyncThunk(
  "subscribers/subscribersList",
  async (url) => {
    const { data } = await axios.get(`${API}${url}`, token);
    return data;
  }
);

export const deleteSubscriber = createAsyncThunk(
  "subscribers/deleteSubscriber",
  async (url) => {
    const { data } = await axios.delete(`${API}${url}`, token);
    return data;
  }
);

export const updateSubscriber = createAsyncThunk(
  "subscribers/updateSubscriber",
  async (body) => {
    const { data } = await axios.put(`${API}${body.url}`, body, token);
    return data;
  }
);

export const subscribersSlice = createSlice({
  name: "subscribers",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(subscribersList.fulfilled, (state, action) => {
      state.subscribers = action.payload;
    });
    builder.addCase(getSubscriber.fulfilled, (state, action) => {
      state.subscriber = action.payload;
    });
    builder.addCase(deleteSubscriber.fulfilled, (state, action) => {
      state.updateUserStatus = action.payload;
    });
    builder.addCase(updateSubscriber.fulfilled, (state, action) => {
      state.updateUserStatus = action.payload;
    });
  },
});
export default subscribersSlice.reducer;
