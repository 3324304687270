export const selectURL1 = (adminUrl, userUrl, id, query) => {
  const userData = JSON.parse(localStorage.getItem("user"));
  if (userData.user_type === "admin") {
    if (id) {
      if (query) {
        return `${adminUrl}/${id}/${query}`;
      } else {
        return `${adminUrl}/${id}`;
      }
    } else {
      let nul = null;
      if (query) {
        return `${adminUrl}/${nul}/${query}`;
      } else {
        return `${adminUrl}/${nul}`;
      }
    }
  } else {
    if (id) {
      if (query) {
        return `${userUrl}/${id}/${query}`;
      } else {
        return `${userUrl}/${id}`;
      }
    } else {
      if (query) {
        return `${userUrl}/${query}`;
      } else {
        return userUrl;
      }
    }
  }
};

export const selectURL = (adminUrl, userUrl, id) => {
  const userData = JSON.parse(localStorage.getItem("user"));
  return userData.user_type === "admin"
    ? id
      ? `${adminUrl}/${id}`
      : adminUrl
    : id
    ? `${userUrl}/${id}`
    : userUrl;
};

export const Loading = () => (
  <div className="spinner-border text-primary" role="status">
    <span className="sr-only">Loading...</span>
  </div>
);

export const loading = () => (
  <div>
    <div className="spinner-grow text-warning" role="status">
      <span className="sr-only">Loading...</span>
    </div>
    <div className="spinner-grow text-info" role="status">
      <span className="sr-only">Loading...</span>
    </div>
    <div className="spinner-grow text-danger" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

export const selectOptions = [
  { id: "one time", name: "One Time", value: "one time" },
  { id: "daily", name: "Daily", value: "daily" },
  { id: "weekly", name: "Weekly", value: "weekly" },
  { id: "monthly", name: "Monthly", value: "monthly" },
  { id: "annually", name: "Annually", value: "annually" },
];

export const selectTimeZones = [
  {
    id: "Universal Coordinated Time",
    name: "Universal Coordinated Time",
    value: "Universal Coordinated Time",
  },
  {
    id: "European Central Time",
    name: "European Central Time",
    value: "European Central Time",
  },
  {
    id: "Eastern European Time",
    name: "Eastern European Time",
    value: "Eastern European Time",
  },
  {
    id: "(Arabic) Egypt Standard Time",
    name: "(Arabic) Egypt Standard Time",
    value: "(Arabic) Egypt Standard Time",
  },
  {
    id: "Eastern African Time",
    name: "Eastern African Time",
    value: "Eastern African Time",
  },
  {
    id: "Middle East Time",
    name: "Middle East Time",
    value: "Middle East Time",
  },
  { id: "Near East Time", name: "Near East Time", value: "Near East Time" },
  {
    id: "Pakistan Lahore Time",
    name: "Pakistan Lahore Time",
    value: "Pakistan Lahore Time",
  },
  {
    id: "India Standard Time",
    name: "India Standard Time",
    value: "India Standard Time",
  },
  {
    id: "Bangladesh Standard Time",
    name: "Bangladesh Standard Time",
    value: "Bangladesh Standard Time",
  },
  {
    id: "Vietnam Standard Time",
    name: "Vietnam Standard Time",
    value: "Vietnam Standard Time",
  },
  {
    id: "China Taiwan Time",
    name: "China Taiwan Time",
    value: "China Taiwan Time",
  },
  {
    id: "Japan Standard Time",
    name: "Japan Standard Time",
    value: "Japan Standard Time",
  },
  {
    id: "Australia Central Time",
    name: "Australia Central Time",
    value: "Australia Central Time",
  },
  {
    id: "Australia Eastern Time",
    name: "Australia Eastern Time",
    value: "Australia Eastern Time",
  },
  {
    id: "Solomon Standard Time",
    name: "Solomon Standard Time",
    value: "Solomon Standard Time",
  },
  {
    id: "New Zealand Standard Time",
    name: "New Zealand Standard Time",
    value: "New Zealand Standard Time",
  },
  {
    id: "Midway Islands Time",
    name: "Midway Islands Time",
    value: "Midway Islands Time",
  },
  {
    id: "Hawaii Standard Time",
    name: "Hawaii Standard Time",
    value: "Hawaii Standard Time",
  },
  {
    id: "Alaska Standard Time",
    name: "Alaska Standard Time",
    value: "Alaska Standard Time",
  },
  {
    id: "Pacific Standard Time",
    names: "Pacific Standard Time",
    value: "Pacific Standard Time",
  },
  {
    id: "Phoenix Standard Time",
    name: "Phoenix Standard Time",
    value: "Phoenix Standard Time",
  },
  {
    id: "Mountain Standard Time",
    name: "Mountain Standard Time",
    value: "Mountain Standard Time",
  },
  {
    id: "Central Standard Time",
    name: "Central Standard Time",
    value: "Central Standard Time",
  },
  {
    id: "Eastern Standard Time",
    name: "Eastern Standard Time",
    value: "Eastern Standard Time",
  },
  {
    id: "Indiana Eastern Standard Time",
    name: "Indiana Eastern Standard Time",
    value: "Indiana Eastern Standard Time",
  },
  {
    id: "Puerto Rico and US Virgin Islands Time",
    name: "Puerto Rico and US Virgin Islands Time",
    value: "Puerto Rico and US Virgin Islands Time",
  },
  {
    id: "Canada Newfoundland Time",
    name: "Canada Newfoundland Time",
    value: "Canada Newfoundland Time",
  },
  {
    id: "Argentina Standard Time",
    name: "Argentina Standard Time",
    value: "Argentina Standard Time",
  },
  {
    id: "Brazil Eastern Time",
    name: "Brazil Eastern Time",
    value: "Brazil Eastern Time",
  },
  {
    id: "Central African Time",
    name: "Central African Time",
    value: "Central African Time",
  },
];
