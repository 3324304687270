import Joi from "joi";
import tlds from "tlds";
export const validation = (data) => {
  const schema = Joi.object({
    username: Joi.string()
      .min(5)
      .required()
      .email({ minDomainSegments: 2, tlds: { allow: tlds } })
      .messages({
        "string.empty": `Email cannot be an empty field`,
        "string.min": `Email should have a minimum length of {#limit}`,
        "string.email": `Email must be a valid email`,
      }),
    password: Joi.string().min(6).required().messages({
      "string.empty": `Password cannot be an empty field`,
      "string.min": `Password should have a minimum length of {#limit}`,
    }),
  });
  const { error } = schema.validate(data, { abortEarly: false });
  // console.log(
  //   error.details.map((errDetail) => errDetail.type),
  //   error
  // );
  if (!error) return null;
  const errors = {};
  for (let item of error.details) errors[item.path[0]] = item.message;
  return errors;
};
